import React, { FC } from 'react'
import styled from 'styled-components'
import Layout from 'components/Layout'
import { Container, Flex } from 'styles/globalStyle'
import { Title, Text } from 'components/theme'

const StyledMentions = styled(Container)`
  a {
    text-decoration: underline;
    transition: 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  ul {
    color: ${({ theme }) => theme.colors.gray[500]};
    list-style: disc;
    margin: 0.75rem 0 1rem;
    padding-left: 1rem;
    line-height: 1.5rem;
    li:not(:last-child) {
      padding-bottom: 0.5em;
    }
  }
`

const LegalMentionsPage: FC = () => {
  return (
    <Layout onlyFooter>
      <StyledMentions py={['4rem', '6rem']}>
        <Title as="h1" size="lg" mb={['1.5rem', '2.5rem']}>
          Mentions Légales
        </Title>
        <Text size="lg" fontWeight="500" mb="0.75rem">
          ARTICLE 1 : L’éditeur du site
        </Text>
        <Flex flexDirection="column" mb="2rem">
          <Text>
            Le présent site est la propriété de A PLUS CONSULTING, Société par
            actions simplifiée au capital de 1000 euros, immatriculée au RCS de
            Nanterre sous le numéro 907524581 dont le siège est situé au 3
            Avenue Louvois 92190 Meudon Numéro de TVA intracommunautaire :
            FR65907524581 Le directeur de la publication du site web est Saro
            AWAKIAN en qualité de Président et joignable via l’adresse e-mail :{' '}
            <Text as="a" href="mailto:sawakian@audiplus.fr">
              sawakian@audiplus.fr
            </Text>
          </Text>
        </Flex>
        <Text size="lg" fontWeight="500" mb="0.75rem">
          ARTICLE 2 : L’hébergeur et administrateur
        </Text>
        <Text fontWeight="500">Hébergeur :</Text>
        <Text>Microsoft Azure – Microsoft France – SAS</Text>
        <Text>SIRET : 32773318400516</Text>
        <Text>
          Siège social : 39 Quai du Président Roosevelt – 92130
          Issy-les-Moulineaux
        </Text>
        <Text>Capital : 4 240 000 €</Text>
        <Text>TVA Intracommunautaire : FR95327733184</Text>
        <Text>
          Tel :{' '}
          <Text as="a" href="tel:0157324218">
            01 57 32 42 18
          </Text>
        </Text>
        <Text>
          Email :{' '}
          <Text
            as="a"
            href="https://support.microsoft.com/contactus"
            target="_blank"
          >
            https://support.microsoft.com/contactus/#
          </Text>
        </Text>
        <Text>
          Ce site est hébergé sur l’offre Cloud de Microsoft (Azure). Il est
          géographiquement localisé dans le centre d’hébergement de Microsoft en
          Europe de l’Ouest situé en France et aux Pays Bas.
        </Text>
        <Text fontWeight="500" mt="1rem">
          Administrateur :
        </Text>
        <Text>Redfabriq</Text>
        <Text>SIRET : 50170517200010</Text>
        <Text>Siège social : 16 Rue de la Comète, 75007 Paris</Text>
        <Text>Capital : 1 855 000 euros</Text>
        <Text>TVA Intracommunautaire : FR90501705172</Text>
        <Text>
          Email :{' '}
          <Text as="a" href="mailto:contact@redfbriq.com">
            contact@redfbriq.com
          </Text>
        </Text>
        <Text size="lg" fontWeight="500" mt="2rem" mb="0.75rem">
          ARTICLE 3 : Accès au site
        </Text>
        <Text>
          Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de
          force majeure, interruption programmée ou non et pouvant découlant
          d’une nécessité de maintenance. En cas de modification, interruption
          ou suspension des services, le site ne saurait être tenu responsable.
          Le site est mis à jour régulièrement. De la même façon, les mentions
          légales peuvent être modifiées à tout moment : elles s’imposent
          néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent
          possible afin d’en prendre connaissance.
        </Text>
        <Text size="lg" fontWeight="500" mt="2rem" mb="0.75rem">
          ARTICLE 4 : Description des services fournis
        </Text>
        <Text>
          Le site a pour objet de fournir une information concernant l’ensemble
          des activités de la société. La société s’efforce de fournir des
          informations aussi précises que possible. Toutefois, il ne pourra être
          tenu responsable des omissions, des inexactitudes et des carences dans
          la mise à jour, qu’elles soient de son fait ou du fait des tiers
          partenaires qui lui fournissent ces informations. Toutes les
          informations indiquées sur le site sont données à titre indicatif, et
          sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant
          sur le site ne sont pas exhaustifs. Ils sont donnés sous réserve de
          modifications ayant été apportées depuis leur mise en ligne.
        </Text>
        <Text size="lg" fontWeight="500" mt="2rem" mb="0.75rem">
          ARTICLE 5 : Collecte des données et Cookies
        </Text>
        <Text>
          Vos données personnelles peuvent nous être fournies dans les cas
          suivants :
        </Text>
        <ul>
          <li>
            Lorsque vous visitez le site qui utilise des cookies. Axeptio est le
            responsable du traitement de toute information que nous obtenons par
            l’utilisation de cookies. Veuillez-vous reporter à notre politique
            de cookies et à notre centre de préférence en matière de cookies.
          </li>
          <li>Lorsque vous nous contactez à travers le site </li>
        </ul>
        <Text>
          Nous collectons ces données personnelles pour de nombreuses raisons,
          et notamment pour créer et sécuriser votre compte. Les informations
          personnelles recueillies incluent :
        </Text>
        <ul>
          <li>Le nom</li>
          <li>L’adresse email</li>
          <li>Le numéro de téléphone</li>
          <li>La date de naissance</li>
          <li>Vos préférences pour nos communications</li>
        </ul>
        <Text>
          L’ensemble des finalités de traitement de données personnelles sont
          listées ci-après :
        </Text>
        <ul>
          <li>Pour planifier vos rendez-vous au centre </li>
          <li>Pour être rappelez </li>
          <li>Pour les demandes de renseignement </li>
          <li>Pour la fabrication des devis </li>
          <li>Pour l’envoi des offres commerciales et newsletters</li>
        </ul>
        <Text>
          Conformément au règlement général sur la protection des données entré
          en vigueur le 25 Mai 2018, vous avez un droit d’accès, d’opposition et
          de portabilité de vos données personnelles. Pour exercer l’un d’entre
          eux, faites une demande à cette adresse email :{' '}
          <Text as="a" href="mailto:sawakian@audiplus.fr">
            sawakian@audiplus.fr
          </Text>
        </Text>
        <Text size="lg" fontWeight="500" mt="2rem" mb="0.75rem">
          ARTICLE 6 : Propriété intellectuelle
        </Text>
        <Text>
          Toute utilisation, reproduction, diffusion, commercialisation,
          modification de toute ou partie du présent site sans autorisation de
          l’Editeur est prohibée et pourra entraîner des actions et poursuites
          judiciaires comme notamment prévues par le Code de la propriété
          intellectuelle et le Code civil.
        </Text>
        <Text size="lg" fontWeight="500" mt="2rem" mb="0.75rem">
          ARTICLE 7 : Données personnelles pour communication
        </Text>
        <Text>
          Vous pourrez choisir d’accepter ou de refuser de recevoir des
          communications commerciales en indiquant votre choix sur la page
          contact. En acceptant, vous acceptez de recevoir les newsletter
          d’Audiplus et des communications dont le but est de promouvoir nos
          services. Vous pouvez retirer à tout moment votre consentement en
          cliquant sur « Se désabonner » tout en bas de l’email que vous aurez
          reçu d’Audiplus. Audiplus est responsable du traitement de ces données
          destinées à permettre l’envoi des communications aux utilisateurs.
          Audiplus s’engage à protéger la vie privée de ses utilisateurs en
          respectant la réglementation en vigueur à cet égard. Audiplus ne
          partage ni ne divulgue en aucun cas les données personnelles des
          utilisateurs sans leur autorisation préalable.
        </Text>
      </StyledMentions>
    </Layout>
  )
}
export default LegalMentionsPage
